import {isFrench, tr} from "../../../vue-components/helpers/i18n.js";
import {translateMeterDistance} from "../../../vue-components/helpers/vue-filters.js";
import MapMathHelper from "./MapMathHelper.js";
import Vector from "./Vector.js";
import store from "@/store.js"

export default class MapInstructions {
    constructor(index, previousItem, currentItem, nextItem) {
        this.index = index;
        this.previousItem = previousItem;
        this.currentItem = currentItem;
        this.nextItem = nextItem;
        // set later
        this.nextInstruction = null;
        // in case with merged multiple links
        this.links = [];
        this.icon = null;
        this.instructions = tr(currentItem.instructions);
        this.instructionGlyph = currentItem.instructionGlyph;

        // console.log(store.state);
        this.fromFloor = store.state.mapEditor.map.getFloorById(this.currentItem.floorId);
        this.toFloor = this.fromFloor;

        if (!this.previousItem) {
            this.previousVector = new Vector(0, -1);
            if (this.currentItem.angle < 0 || this.currentItem.angle > 0) {
                this.previousVector.fromAngle(this.currentItem.angle - 180);
            }
            this.distance = 0;
        } else {
            this.previousVector = new Vector(this.previousItem.x - this.currentItem.x, this.currentItem.y - this.previousItem.y);

            this.link = currentItem.links.find(l => l.isLinkBetween(this.currentItem, this.previousItem));
            this.links.push(this.link);
            // replace fromFloor based on the link floor, since stairs and elevators are placed on the Ground Floor, but can be accessed from many floors
            this.fromFloor = store.state.mapEditor.map.getFloorById(this.link.floorId)
            this.distance = Math.round(this.link.distance);
        }

        if (this.nextItem) {
            this.nextVector = new Vector(this.currentItem.x - this.nextItem.x, this.nextItem.y - this.currentItem.y);
            this.nextLink = currentItem.links.find(l => l.isLinkBetween(this.currentItem, this.nextItem));

            if (this.nextLink) {
                // replace fromFloor based on the link floor, since stairs and elevators are placed on the Ground Floor, but can be accessed from many floors
                this.toFloor = store.state.mapEditor.map.getFloorById(this.nextLink.floorId);
            } else {
                this.toFloor = this.fromFloor;
            }
        } else {
            this.nextVector = new Vector(0, -1);
        }

        // invert y to get correct angle since coordinates are from top to bottom
        this.previousVector.invertY();
        this.nextVector.invertY();

        this.fromAngle = this.previousVector.getAngle();
        this.toAngle = this.nextVector.getAngle();
        this.deltaAngle = this.previousVector.getDiffAngle(this.nextVector);

        this.image360 = this.currentItem.image360 || null;
        this.offsetAngle = this.currentItem.offsetAngle || 0;

        if (this.index === 0 && !this.image360 && this.nextItem?.image360) {
            this.image360 = this.nextItem.image360;
        }

        this.image = this.currentItem.image || null;
        if (!this.image && this.currentItem.cmsItem) {
            this.image = this.currentItem.cmsItem.getPreviewImage();
        }

        // called later by setNextInstruction
        this.updateInstructions();

        // console.log(`${currentItem.index} -> ${this.deltaAngle}° - ${this.direction}`);
    }

    isVisible() {
        if (this.index === 0 && this.nextLink) {
            return this.nextLink.visible && !this.nextLink.optional;
        } else if (this.link) {
            return this.link.visible && !this.link.optional;
        }
        return true;
    }

    setArrival(end) {
        this.direction = `arrived`;
        this.icon = `fa-flag-checkered`;
        this.description = tr(`You arrived at ${end.getFullName()}|Vous êtes arrivés à ${end.getFullName()}`);
        this.addInstructions();
    }

    setNextInstruction(nextInstruction) {
        this.nextInstruction = nextInstruction;
        this.updateInstructions();
    }

    updateInstructions() {
        this.showDistance = true;
        this.description = ``;
        this.action = tr(`Walk|Marchez`);
        this.direction = MapMathHelper.getAngleDirection(this.deltaAngle);

        this.currentLocation = tr(this.getItemLocation(this.currentItem));

        switch (this.direction) {
            case `right`:
                this.description = tr(`to your right|sur votre droite`);
                this.icon = `fa-turn-right`;
                break;
            case `light-right`:
                this.description = tr(`to your right|sur votre droite`);
                this.icon = `fa-arrow-up-right`;
                break;
            case `left`:
                this.description = tr(`to your left|sur votre gauche`);
                this.icon = `fa-turn-left`;
                break;
            case `light-left`:
                this.description = tr(`to your left|sur votre gauche`);
                this.icon = `fa-arrow-up-left`;
                break;
            case `forward`:
                this.description = tr(`forward|devant vous`);
                this.icon = `fa-up-long`;
                break;
            case `backward-left`:
            case `backward-right`:
                this.description = tr(`backward|derrière vous`);
                this.icon = `fa-turn-left-down`;
                break;
        }

        if (this.currentItem?.type === `elevator`) {
            this.currentLocation = tr(`In the elevator|Dans l'ascenseur`);
        } else if (this.previousItem?.type === `stairs`) {
            this.currentLocation = tr(`In the staircase|Dans les escaliers`);
        }

        if (this.toFloor !== this.fromFloor) {
            if (this.toFloor.floor > this.fromFloor.floor) {
                this.direction = `up`;
                if (this.currentItem.type === `elevator`) {
                    this.icon = `fa-elevator`;
                } else if (this.currentItem.type === `stairs`) {
                    this.icon = `fa-stairs`;
                } else {
                    this.icon = `fa-up-from-dotted-line`;
                }
                this.description = tr(`Go Up to the ${this.toFloor.getName()}|Montez au ${this.toFloor.getName()}`);
                this.showDistance = false;
                return;
            } else {
                this.direction = `down`;
                if (this.currentItem.type === `elevator`) {
                    this.icon = `fa-elevator`;
                } else if (this.currentItem.type === `stairs`) {
                    this.icon = `fa-stairs`;
                } else {
                    this.icon = `fa-down-from-dotted-line`;
                }
                this.description = tr(`Go Down to the ${this.toFloor.getName()}|Descendez au ${this.toFloor.getName()}`);

                this.showDistance = false;
                return;
            }
        } else {
            if (this.index === 0) {
                // this.action = tr(`Go|Partez`);
                // first item
                if (!this.currentItem.angle) {
                    this.description = tr(`Following the arrow|En direction de la flèche`);
                } /*else {
                    switch (this.direction) {
                        case `right`:
                            this.description = tr(`right|à droite`);
                            break;
                        case `light-right`:
                            this.description = tr(`right|à droite`);
                            break;
                        case `left`:
                            this.description = tr(`left|à gauche`);
                            break;
                        case `light-left`:
                            this.description = tr(`left|à gauche`);
                            break;
                        case `forward`:
                            this.description = tr(`forward|devant vous`);
                            break;
                        case `backward-left`:
                        case `backward-right`:
                            this.description = tr(`backward|derrière vous`);
                            break;
                    }
                }*/
            } else if (this.nextItem) {
                if (this.nextItem.type === `elevator`) {
                    if (this.distance < 6) {
                        this.showDistance = false;
                        this.description = tr(`Take the elevator|Prenez l'ascenseur`);
                    } else {
                        this.description += tr(`, take the elevator|, prenez l'ascenseur`);
                    }
                    return;
                }
            }
        }
        this.addInstructions();
    }

    addInstructions() {
        if (this.nextInstruction?.currentItem.getLocationDescription()) {
            let location = this.getItemLocation(this.nextInstruction.currentItem, `until|jusqu'`);
            this.description = `${this.description.toLowerCase()}, ${location}`;
        }

        if (!tr(this.nextItem?.instructions)) {
            if (this.nextItem?.type === `door`) {
                this.description += tr(`, go through the door|, passez par la porte`);
            }
        }
    }

    getItemLocation(item, prefix) {
        let location = item.getLocationDescription();
        if (!location) {
            return ``;
        }
        const pronoun = this.getPronoun(location);
        if (isFrench()) {
            location = location.replace(/^(le\s)?(les\s)?/i, ``);
            if (prefix) {
                location = `${tr(prefix)}${pronoun.toLowerCase()} ${location.toLowerCase()}`;
            } else {
                location = `${pronoun} ${location.toLowerCase()}`;
            }
        } else {
            if (prefix) {
                location = `${tr(prefix)} ${location}`;
            } else {
                location = `${location}`;
            }
        }
        return location;
    }

    getPronoun(location) {
        if (isFrench()) {
            if (location.toLowerCase().startsWith(`les`)) {
                return `Aux`;
            } else if (location.toLowerCase().startsWith(`le`)) {
                return `Au`;
            } else {
                return `À`;
            }
        } else {
            return ``;
        }
    }

    mergeWith(navInstruction) {
        // console.log(`merging ${navInstruction.index} in ${this.index}`);
        // this.index = navInstruction.index;
        this.distance += navInstruction.distance;
        this.fromAngle = navInstruction.fromAngle;
        // this.toAngle = navInstruction.toAngle;
        this.deltaAngle += navInstruction.deltaAngle;
        this.originalItem = navInstruction.currentItem;
        if (navInstruction.link) {
            this.links = this.links.concat(navInstruction.links);
        }
        if (this.nextItem) {
            if (!this.instructions && this.nextItem.instructions) {
                this.instructions = tr(navInstruction.nextItem.instructions);
            }
            if (!this.instructionGlyph) {
                this.instructionGlyph = navInstruction.nextItem.instructionGlyph;
            }
        }
        this.updateInstructions();
    }

    getTextToSpeech() {
        let textToSpeech = ``;
        if (this.showDistance && this.nextInstruction?.distance > 0) {
            textToSpeech = tr(`${this.action} ${translateMeterDistance(this.nextInstruction.distance)} |${this.action} ${this.nextInstruction.distance}m `);
        }

        textToSpeech += this.description;

        if (this.instructions) {
            textToSpeech += `. ` + this.instructions;
        }

        return textToSpeech;
    }
}
