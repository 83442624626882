import DatedModel from "../DatedModel.js";
import {type} from "../../Model.js"

export default class CmsNavLog extends DatedModel {
    static collection = `cms_nav_logs`;
    static endpoint = `/api/public/nav-logs`;
    static primaryKey = [`dealerid`, `startPoint.id`, `endPoint.id`];

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true
    }

    startPoint = {
        [type]: Object,
        required: true
    }

    endPoint = {
        [type]: Object,
        required: true
    }

    started = {
        [type]: Number,
        default: () => 0,
        required: true,
        incrementOnly: true
    }

    finished = {
        [type]: Number,
        default: () => 0,
        required: true,
        incrementOnly: true
    }

    constructor() {
        super(...arguments);
    }
}
