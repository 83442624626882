import Model, {type} from "../../Model.js";

export default class Feedback extends Model {
    static collection = `feedback`;
    static endpoint = `/api/public/feedback`;

    project = {
        [type]: String,
        flags: [F_LOWER],
        required: true
    }

    type = {
        [type]: String,
        flags: [F_LOWER],
        validation(value) {
            return [`error`, `rating`].includes(value);
        },
        required: true
    }

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true
    }

    rating = {
        [type]: Number,
        validation(value) {
            return value >= 0 && value <= 5;
        },
        required: false
    }

    message = {
        [type]: String,
        required: false
    }

    data = {
        [type]: Object,
        required: false
    }

    dateCreation = {
        [type]: Date,
        required: true,
        default: () => new Date()
    }

    constructor() {
        super(...arguments);
    }
}
